import _string3 from "./string";
import _method3 from "./method";
import _number3 from "./number";
import _boolean3 from "./boolean";
import _regexp3 from "./regexp";
import _integer3 from "./integer";
import _float3 from "./float";
import _array3 from "./array";
import _object3 from "./object";
import _enum3 from "./enum";
import _pattern3 from "./pattern";
import _date3 from "./date";
import _required3 from "./required";
import _type3 from "./type";
var exports = {};
exports.__esModule = true;
var _string = _string3;

var _string2 = _interopRequireDefault(_string);

var _method = _method3;

var _method2 = _interopRequireDefault(_method);

var _number = _number3;

var _number2 = _interopRequireDefault(_number);

var _boolean = _boolean3;

var _boolean2 = _interopRequireDefault(_boolean);

var _regexp = _regexp3;

var _regexp2 = _interopRequireDefault(_regexp);

var _integer = _integer3;

var _integer2 = _interopRequireDefault(_integer);

var _float = _float3;

var _float2 = _interopRequireDefault(_float);

var _array = _array3;

var _array2 = _interopRequireDefault(_array);

var _object = _object3;

var _object2 = _interopRequireDefault(_object);

var _enum = _enum3;

var _enum2 = _interopRequireDefault(_enum);

var _pattern = _pattern3;

var _pattern2 = _interopRequireDefault(_pattern);

var _date = _date3;

var _date2 = _interopRequireDefault(_date);

var _required = _required3;

var _required2 = _interopRequireDefault(_required);

var _type = _type3;

var _type2 = _interopRequireDefault(_type);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

exports["default"] = {
  string: _string2["default"],
  method: _method2["default"],
  number: _number2["default"],
  boolean: _boolean2["default"],
  regexp: _regexp2["default"],
  integer: _integer2["default"],
  float: _float2["default"],
  array: _array2["default"],
  object: _object2["default"],
  "enum": _enum2["default"],
  pattern: _pattern2["default"],
  date: _date2["default"],
  url: _type2["default"],
  hex: _type2["default"],
  email: _type2["default"],
  required: _required2["default"]
};
exports = exports["default"];
export default exports;